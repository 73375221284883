.profile-container {
    color: white;
    width: 100%;
    height: 100%;
    padding-top: 200px;
}

.apartment-details-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.profile-first {
    display: flex;
    gap: 20px;
    padding: 0 160px;
    margin-bottom: 50px;
    align-items: center;
}

.isPremium {
    background-color: #1b1a1e;
    border-radius: 20px;
    border: 1px solid #292730;
    padding: 12px 20px;
    color: #a28adf;
    display: inline-block;
}

.isBasic {
    background-color: #141414;
    border-radius: 20px;
    border: 1px solid #262626;
    padding: 12px 20px;
    color: white;
    display: inline-block;
}

.apartment-details-card-profile {
    border-radius: 12px;
    border: 1px solid #262626;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 0 160px 100px 160px;
}

.button-profile-row {
    padding: 0 160px;
    margin: 30px 0 100px 0;
    display: flex;
    gap: 10px;
}

.apply-history {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    padding: 0 160px;
}

.apartment-item-status {
    color: white;
    font-size: 16px;
}

.profile-apartment-item-buttons {
    display: flex;
    gap: 20px;
    margin-left: auto;
}

.profile-accept {
    background-color: #A2D33B;
    border-radius: 8px;
    border: none;
    padding: 16px 24px;
    color: black;
    cursor: pointer;
    font-size: 14px;
}

.profile-decline {
    background-color: #F1273F;
    border-radius: 8px;
    border: none;
    padding: 16px 24px;
    color: black;
    cursor: pointer;
    font-size: 14px;
}

.profile-pending {
    background-color: #f1a027;
    border-radius: 8px;
    border: none;
    padding: 16px 24px;
    color: black;
    cursor: pointer;
    font-size: 14px;
}

@media (max-width: 700px) {
    .profile-container {
        color: white;
        width: 100%;
        height: 100%;
        padding-top: 100px;
    }

    .apartment-details-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .profile-first {
        padding: 0;
    }

    .apartment-details-card-profile {
        margin: 0 0 100px 0;
    }

    .button-profile-row {
        padding: 0;
    }

    .apply-history {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
        padding: 0;
    }
}