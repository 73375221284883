

.admin-field {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.admin-field h2 {
    text-align: center;
    color: #FFF;
  }
  
  /* Labels */
  .admin-field label {
    color: white;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px; /* Adjust the gap between label and input */
  }
  
  .admin-field input[type="text"],
  .admin-field input[type="email"],
  .admin-field input[type="password"],
  .admin-field input[type="number"] {
    background-color: #1A1A1A;
    border-radius: 6px;
    border: 1px solid #262626;
    padding: 16px;
    font-size: 14px;
    color: #666666; /* Color when not filled */
  }

  .admin-field textarea {
    background-color: #1A1A1A;
    border-radius: 6px;
    border: 1px solid #262626;
    padding: 16px;
    font-size: 14px;
    color: #666666; 
    height: 250px;
  }

  .admin-field select {
    background-color: #1A1A1A;
    border-radius: 6px;
    border: 1px solid #262626;
    padding: 16px;
    font-size: 14px;
    color: #666666;
    cursor: pointer;
  }

  .admin-field select:focus {
    outline: none;
  }

  .admin-field textarea:focus {
    color: white;
    font-family: 'Urbanist';
    outline: none;
  }
  
  /* Input: Focus and Filled */
  .admin-field input[type="text"]:focus,
  .admin-field input[type="text"]:valid,
  .admin-field input[type="email"]:focus,
  .admin-field input[type="email"]:valid,
  .admin-field input[type="password"]:focus,
  .admin-field input[type="password"]:valid,
  .admin-field input[type="number"]:focus,
  .admin-field input[type="number"]:valid {
    color: white;
  }
  
  /* Checkbox and its Label */
  .admin-field input[type="checkbox"] {
    margin-right: 10px; /* Gap between checkbox and its label */
  }