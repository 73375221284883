.broker-details-card-profile {
    border-radius: 12px;
    border: 1px solid #262626;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 0 0 50px 0
}

.broker-details-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

.isBroker {
    background-color: #4a3c35;
    border-radius: 20px;
    border: 1px solid #F9BF9B;
    padding: 12px 20px;
    color: #F9BF9B;
    display: inline-block;
}

.promocode-card {
    border-radius: 12px;
    background-color: #4a3c35;
    border: 1px solid #F9BF9B;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 0 50px 0
}

.promocode-title {
    font-size: 24px;
    color: #F9BF9B;
    margin-bottom: 10px;
    font-weight: 700;
}

.promocode-text {
    font-size: 16px;
    color: #d2ae98;
}

.broker-promocode-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-bottom: 30px;
}

.broker-promocode-grid-label {
    color: #d2ae98;
    font-size: 14px;
}

.broker-apartment {
    display: flex;
    flex-direction: column;
    gap: 50px;
    border-radius: 10px;
    border: 1px solid #262626;
    padding: 20px;
}

.broker-apartment-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.broker-user-details-grid {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 20px;
}

.broker-user-pfp {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
}

.broker-users-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.broker-user {
    display: flex;
    flex-direction: column;
    gap: 30px;
    background-color: #272727;
    padding: 20px;
    border-radius: 10px;
}

.broker-user-buttons {
    display: flex;
    gap: 10px;
    height: 100%;
}

.broker-accept {
    background-color: #A2D33B;
    border-radius: 8px;
    border: none;
    padding: 10px 24px;
    color: black;
    cursor: pointer;
    font-size: 14px;
}

.broker-decline {
    background-color: #F1273F;
    border-radius: 8px;
    border: none;
    padding: 10px 24px;
    color: black;
    cursor: pointer;
    font-size: 14px;
}

.broker-pending {
    background-color: #f1a027;
    border-radius: 8px;
    border: none;
    padding: 10px 24px;
    color: black;
    cursor: pointer;
    font-size: 14px;
}

.button-survey-upload {
    background-color: #141414;
    border-radius: 10px;
    border: none;
    padding: 8px 10px;
    color: white;
    cursor: pointer;
}


@media (max-width: 700px) {
    .broker-details-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }

    .broker-promocode-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-bottom: 30px;
    }

    .broker-apartment-item {
        display: flex;
        flex-direction: column;
        justify-content: unset;
        gap: 20px;
        align-items: unset;
    }

    .broker-user-details-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }
}
