.header-mobile {
    background-color: #1A1A1A;
    position: fixed;
    bottom: -5px;
    left: 0;
    width: 100%;
    z-index: 9999;
}

.header-mobile-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #262626;
    padding: 20px 30px;
    gap: 10px;
}

.header-mobile-menu-item {
    width: 20px;
    height: 20px;
    object-fit: cover;
}

.header-mobile-menu-item-profile {
    width: 20px;
    height: 20px;
    object-fit: cover;
    border-radius: 50%;
}

@media (min-width: 700px) {
    .header-mobile {
        display: none;
    }
}
