.dashboard-container {
    height: 100%;
    padding: 160px;
}

.dashboard-welcome {
    display: flex;
    margin-bottom: 50px;
    gap: 20px;
    align-items: center;
}

.dashboard-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 100px;
}

.dashboard-card {
    border-radius: 10px;
    border: 1px solid #262626;
    box-shadow: rgba(247, 247, 247, 0.16) 0px 1px 4px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-decoration: none;
}

.dashboard-card-title {
    color: white;
    font-size: 24px;
    width: 100%;
    font-weight: 600;
}

.dashboard-card-text {
    color: #999999;
    font-size: 20px;
    width: 100%;
}

.apartments-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.apartment-item {
    border-radius: 10px;
    border: 1px solid #262626;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.apartment-item-info {
    display: flex;
    gap: 20px;
    align-items: center;
}

.apartment-item-info-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.apartment-item-title {
    color: white;
    font-size: 20px;
    width: 100%;
    font-weight: 600;
}

.apartment-item-text {
    color: white;
    font-size: 16px;
    width: 100%;
}

.archived-text {
    color: rgb(255, 138, 138);
    font-size: 12px;
    width: 100%;
}

.active-text {
    color: rgb(142, 255, 138);
    font-size: 12px;
    width: 100%;
}

.apartment-item-buttons {
    display: flex;
    gap: 20px;
}

.apartment-item-media {
    width: 70px;
    height: 70px;
    border-radius: 6px;
    object-fit: cover;
}


@media (max-width: 700px) {
    .dashboard-container {
        height: 100%;
        padding: 100px 0;
    }

    .dashboard-welcome {
        display: flex;
        margin-bottom: 30px;
    }

    .dashboard-cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-bottom: 50px;
    }

    .dashboard-card {
        border-radius: 10px;
        border: 1px solid #262626;
        box-shadow: rgba(247, 247, 247, 0.16) 0px 1px 4px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        text-decoration: none;
    }

    .dashboard-card-title {
        color: white;
        font-size: 20px;
        width: 100%;
        font-weight: 600;
    }

    .apartment-item {
        border-radius: 10px;
        border: 1px solid #262626;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: unset;
        gap: 20px;
        align-items: unset;
    }

    .apartment-item-info {
        display: flex;
        gap: 20px;
        align-items: unset;
    }

    .apartment-item-title {
        color: white;
        font-size: 18px;
        width: 100%;
        font-weight: 600;
    }
    
    .apartment-item-text {
        color: white;
        font-size: 14px;
        width: 100%;
    }

    .apartment-item-buttons {
        display: flex;
        gap: 10px;
    }
}