.chat-container {
    width: 100%;
    height: 100%;
}

.chat {
    display: flex;
    flex: 100%;
}

.sidebar-users {
    width: 30%;
    display: flex;
    flex-direction: column;
    background-color: #141414;
    border-right: 1px solid #282828;
    padding: 84px 20px 20px 20px;
}

.chat-sidebar-user {
    background-color: #141414;
    display: flex;
    padding: 16px 20px;
    gap: 20px;
    width: calc(100% - 40px);
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}

.chat-sidebar-user:hover {
    background-color: #252525;
    border-radius: 12px;
}

.chat-messages {
    display: flex;
    flex-direction: column;
    /* background-color: #050505; */
    background-image: url('../../assets/bgchat.png');
    background-size: cover;
    width: 70%;
    height: 100vh;
}

.messages-type {
    color: white;
    position: fixed;
    bottom: 0;
    width: 70%;
    background-color: #141414;
    border-top: 1px solid #282828;
    justify-content: center;
    display: flex;
    padding: 10px 0;
    align-items: center;
    gap: 20px;
}

.messages-type input {
    padding: 15px 20px;
    border-radius: 12px;
    background-color: #252525;
    color: #999999;
    font-size: 16px;
    flex: 1;
    border: none;
    font-family: 'Urbanist';
    /* margin-right: 50px; */
}

.messages-type input:focus {
    outline: none;
    font-family: 'Urbanist';
    color: white
}

.messages-type input:valid {
    outline: none;
    font-family: 'Urbanist';
    color: white
}

.chat-one-user {
    background-color: #141414;
    border-bottom: 1px solid #282828;
    display: flex;
    padding: 16px 50px;
    gap: 20px;
    position: fixed;
    top: 82px;
    width: 100%;
    align-items: center;
    z-index: 9998;
}

.chat-one-user-column {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.chat-one-username {
    font-size: 18px;
    font-weight: 600;
    color: white;
}

.chat-one-text {
    font-size: 14px;
    font-weight: 600;
    color: #999999;

    max-width: 160px; /* Adjust the width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-one-text-active {
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;

    max-width: 160px; /* Adjust the width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-one-user-pfp {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
}

.messages {
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding: 230px 50px 150px 50px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.groupped-messages {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.messages::-webkit-scrollbar {
    display: none;
}

.message-date {
    color: #B5B5B5;
    font-size: 10px;
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-weight: 700;
}

.message-sent {
    background-color: #703BF7;
    color: white;
    padding: 10px 36px 10px 10px;
    border-radius: 12px 12px 0 12px;
    margin-left: auto;
    position: relative;
    max-width: 400px;

    word-wrap: break-word;
    overflow-wrap: break-word;
}

.message-received {
    background-color: #232323;
    color: white;
    padding: 10px 36px 10px 10px;
    border-radius: 12px 12px 12px 0;
    margin-right: auto;
    position: relative;
    max-width: 400px;

    word-wrap: break-word;
    overflow-wrap: break-word;
}

.message-photo-sent {
    background-color: #703BF7;
    color: white;
    border-radius: 12px 12px 0 12px;
    margin-left: auto;
    position: relative;
    padding: 1px 1px 0 1px;
}

.message-photo-received {
    background-color: #232323;
    color: white;
    border-radius: 12px 12px 12px 0;
    margin-right: auto;
    position: relative;
    padding: 1px 1px 0 1px;
}

.message-photo-date {
    color: #ffffff;
    font-size: 10px;
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-weight: 700;
    border-radius: 12px;
    padding: 3px 5px;
    background: rgba(0,0,0,0.5);
}

.date-label {
    border-radius: 12px;
    background-color: #1F123F;
    color: white;
    font-size: 16px;
    padding: 7px 15px;
    margin: 30px auto;
}

.chat-sidebar-date {
    color: white;
    margin-left: auto;
    font-size: 12px;
    margin-bottom: auto;
    font-weight: 700;
}

.chat-sidebar-user-active {
    background-color: #703BF7;
    border-radius: 12px;
    display: flex;
    padding: 16px 20px;
    gap: 20px;
    width: calc(100% - 40px);
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}

.icons-row {
    display: flex;
    gap: 20px;
    margin-left: 50px
}

.arrow-chat-icon {
    display: none;
}

.arrow-chat-icon img {
    display: none;
}

.message-apartment-photo {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    object-fit: cover;
}

.phone-button {
display: none;
}

.phone-button-icon {
    width: 24px;
    height: 24px;
    object-fit: cover;
}

@media (max-width: 700px) {
    .sidebar-users {
        display: none;
    }

    .phone-button {
        margin-left: auto;
        margin-right: 30px;
        background-color: transparent;
        border: none;
        display: block;
    }

    .chat-messages {
        display: flex;
        flex-direction: column;
        background-image: none;
        background-size: cover;
        width: 100%;
        height: 100vh;
    }

    .chat-one-user {
        padding: 10px 0;
        top: 0;
        gap: 10px;
        border-bottom: none;
    }

    .messages-type {
        color: white;
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: #141414;
        border-top: none;
        justify-content: center;
        display: flex;
        padding: 10px 0;
        align-items: center;
        gap: 20px;
    }

    .message-sent {
        max-width: 250px;
    }
    
    .message-received {
        max-width: 250px;
    }

    .message-photo-sent {
        max-width: 70%;
    }
    
    .message-photo-received {
        max-width: 70%;
    }

    .messages-type input {
        padding: 15px 20px;
        border-radius: 12px;
        background-color: #252525;
        color: #999999;
        font-size: 16px;
        flex: 1;
        border: none;
        font-family: 'Urbanist';
        -webkit-appearance: none; /* Remove default styling on iOS */
        -moz-appearance: none; /* Remove default styling on Firefox */
        appearance: none;
    }

    .icons-row {
        margin: 0;
    }

    .messages {
        display: flex;
        flex-direction: column;
        gap: 3px;
        padding: 160px 0 150px 0;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .header-chat {
        display: none;
    }

    .chat-one-username {
        font-size: 16px;
        font-weight: 600;
        color: white;
    }
    
    .chat-one-text {
        font-size: 12px;
        font-weight: 600;
        color: #999999;
    }
    
    .chat-one-text-active {
        font-size: 12px;
        font-weight: 600;
        color: #ffffff;
    }
    
    .chat-one-user-pfp {
        width: 36px;
        height: 36px;
        object-fit: cover;
        border-radius: 50%;
    }

    .arrow-chat-icon {
        display: flex;
        background-color: #141414;
        border-radius: 10px;
        border: 1px solid #262626; 
        padding: 12px;
        text-decoration: none;
        color: white;
        gap: 7px; 
        align-items: center;
        display: flex;
        margin-right: 10px
    }

    .arrow-chat-icon img {
        display: flex;
    }
}

