.header {
    background-color: #1A1A1A;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #262626;
    padding: 20px 160px;
    font-size: 14px;
}

.header-first {
    width: 33%;
}

.header-menu {
    display: flex;
    color: white;
    text-decoration: none;
    align-items: center;
    width: 33%;
    justify-content: center;
}

.header-right {
    background-color: #141414;
    border-radius: 10px;
    border: 1px solid #262626;
    padding: 12px 20px;
}

.header-menu-item.active {
    background-color: #141414;
    border-radius: 10px;
    border: 1px solid #262626;
    padding: 12px 20px;
    color: white;
}

.header-menu-item {
    color: white;
    padding: 12px 20px;
}

.logo {
    width: 100px;
    object-fit: cover;
}



@media screen and (max-width: 700px) {
    /* .header {
        background-color: #1A1A1A;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9999;
    } */

    .header-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #262626;
        padding: 10px 20px;
        font-size: 14px;
    }

    .header-first {
        display: flex;
        align-items: center;
    }

    .header-menu {
        display: none;
    }

    /* .header-container a {
        display: none;
    } */
    
    .header-right {
        display: none;
    }

    .header-menu-item {
        display: none;
    }

    .profile-header-icon {
        display: none;
    }
}