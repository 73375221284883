.loading-apartment-card {
    display: flex;
    height: 600px;
    flex-direction: column;
    border-radius: 12px;
    border: 1px solid #262626;
    padding: 30px;
    gap: 20px;
}

.loading-apartment-media {
    height: 300px;
    width: 100%;
    border-radius: 6px;
    animation: loadingApt 1.5s infinite ease-in-out;
}

.loading-apartment-info {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.loading-apartment-info-top {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.loading-apartment-title {
    font-size: 18px;
    font-weight: 500;
    animation: loadingApt 1.5s infinite ease-in-out;
    height: 20px;
    width: 80px;
}

.loading-apartment-description {
    font-size: 14px;
    font-weight: 500;
    animation: loadingApt 1.5s infinite ease-in-out;
    height: 20px;
    width: 100%;
}

.loading-card-bottom {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.loading-price-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.loading-apartment-price-tag {
    font-size: 14px;
    color: #999999;
}

.loading-apartment-price {
    font-size: 18px;
    font-weight: 500;
    height: 30px;
    width: 120px;
    animation: loadingApt 1.5s infinite ease-in-out;
}

.loading-filter-tags {
    display: flex;
    gap: 10px;
  }
  
  .loading-filter-tag {
    border: 1px solid #262626;
    border-radius: 28px;
    padding: 5px 40px;
    height: 16px;
    animation: loadingApt 1.5s infinite ease-in-out;
    font-size: 14px;
  }

@media (max-width: 700px) {
    .loading-apartment-card {
        height: 600px;
    }
}
  

@keyframes loadingApt {
    0% {
      background-color: #211f26;
    }
    50% {
      background-color: #121213;
    }
    100% {
      background-color: #211f26;
    }
  }