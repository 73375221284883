.multi-select-dropdown .dropdown-btn {
    background-color: #1A1A1A;
    border-radius: 6px;
    border: 1px solid #262626;
    padding: 16px;
    font-size: 14px;
    color: #666666;
    cursor: pointer;
  }
  
  .multi-select-dropdown .dropdown-content {
    position: absolute;
    background-color: #1A1A1A;
    border-radius: 6px;
    border: 1px solid #262626;
    padding: 16px;
    font-size: 14px;
    color: #666666;
    z-index: 1;
  }
  
  .multi-select-dropdown .dropdown-item {
    display: block;
    padding: 10px;
    cursor: pointer;
    color: white;
  }