.popup-chat {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    border-radius: 12px;
    background-color: #252525;
    border: 1px solid #292730;
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    opacity: 0;
    visibility: hidden;
    animation: popupAppear 0.2s ease-out forwards;
    z-index: 10000;
}

.popup-fullname {
    font-size: 24px;
    color: white;
    margin-bottom: 30px;
}

.popup-chat-row {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    margin-bottom: 10px;
    color: white;
}

.popup-chat-x {
    position: fixed;
    top: 20px;
    right: 36px;
    font-size: 18px;
    color: white;
    cursor: pointer;
    font-weight: 700;
    border: none;
    background-color: transparent;
}

.chatIsPremium {
    font-size: 10px;
    padding: 5px;
    border: 1px solid #292730;
    background-color: #1b1a1e;
    color: #a28adf;
    border-radius: 12px;
}

.sidebar-users-admin {
    width: 30%;
    display: flex;
    flex-direction: column;
    background-color: #141414;
    border-right: 1px solid #282828;
    padding: 84px 20px 20px 20px;
    overflow-y: auto;
}

.chat-sidebar-user-admin {
    background-color: #141414;
    display: flex;
    padding: 16px 20px;
    gap: 20px;
    width: calc(100% - 40px);
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}

.chat-sidebar-user-admin:hover {
    background-color: #252525;
    border-radius: 12px;
}

.chat-sidebar-user-admin-active {
    background-color: #703BF7;
    border-radius: 12px;
    display: flex;
    padding: 16px 20px;
    gap: 20px;
    width: calc(100% - 40px);
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}

.chat-admin-mobile {
    display: none;
}

.chat-admin {
    display: flex;
    flex: 100%;
}

.message-photo {
    max-width: 450px;
    max-height: 450px;
    object-fit: cover;
    border-radius: 12px 12px 0 12px;
    position: relative;
}

.media-sector {
    color: white;
    position: fixed;
    bottom: 70px;
    width: 70%;
    background-color: #141414;
    border-top: 1px solid #282828;
    display: flex;
    padding: 10px 50px;
    align-items: center;
    gap: 20px;
}

.media-sector-media {
    width: 70px;
    height: 50px;
    object-fit: cover;
    border-radius: 12px;
}

.media-sector-media-pdf {
    width: 50px;
    height: 50px;
    border-radius: 12px;
    background-color: red;
    color: white;
    font-weight: 700;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.media-sector-column-text {
    color: rgb(144, 144, 144);
    font-size: 14px;
}

.media-sector-column-name {
    color: white;
    font-size: 14px;
}

.media-sector-name {
    color: white;
    font-size: 18px;
}

.document-message {
    display: flex;
    gap: 10px;
    text-decoration: none;
    align-items: center;
}

.document-message {
color: white;
}

.document-message-click {
    color: rgb(212, 212, 212);
    font-size: 12px;
}

.send-button {
    background-color: #703BF7;
    border-radius: 10px;
     border: 1px solid #262626;
     padding: 12px 20px;
    margin-right: 50px;
     cursor: pointer; 
     text-decoration: none;
     color: white; 
     gap: 7px;
     align-items: center; 
     display: flex
}


@media (max-width: 700px) {
    .sidebar-users-admin {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #141414;
        border-right: none;
        padding: 0;
    }

    .message-photo {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        border-radius: 12px 12px 0 12px;
    }

    .send-button {
        margin-right: 30px;
    }

    /* .sidebar-users-admin input {
        margin: 0;
    } */

    .chat-admin {
        display: none;
    }

    .chat-admin-mobile {
        display: flex;
        flex: 100%;
        margin-top: 60px;
    }

    .media-sector {
        color: white;
        position: fixed;
        bottom: 69px;
        width: 100%;
        background-color: #141414;
        border-top: none;
        justify-content: unset;
        display: flex;
        padding: 10px 0;
        align-items: center;
        gap: 20px;
    }
}