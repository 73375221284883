@media (min-width: 700px) {
    .footer-container {
        border-top: 1px solid #262626;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer-row {
        background-color: #1A1A1A;
        display: flex;
        gap: 30px;
        padding: 20px 0;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .footer-text {
        color: white;
        font-size: 12px;
        text-decoration: none;
    }
}

@media (max-width: 700px) { 
    .footer-container { 
        display: none;
    }
}