/* Sign Up Form General Styles */
.signup-form {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Gap between each field */
    max-width: 400px; /* Adjust based on your preference */
    margin: 100px auto; /* Center the form on the page */
  }
  
  .signup-form h2 {
    text-align: center;
    color: #FFF;
  }

  .forms-title {
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
    margin-top: 30px;
  }

  .forms-text {
    color: #FFF;
    font-size: 16px;
  }
  
  /* Labels */
  .signup-form label {
    color: white;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px; /* Adjust the gap between label and input */
  }
  
  /* Inputs */
  .signup-form input[type="text"],
  .signup-form input[type="email"],
  .signup-form input[type="password"],
  .signup-form input[type="number"], .signup-form select {
    background-color: #1A1A1A;
    border-radius: 6px;
    border: 1px solid #262626;
    padding: 16px;
    font-size: 14px;
    color: #666666; /* Color when not filled */
  }
  
  /* Input: Focus and Filled */
  .signup-form input[type="text"]:focus,
  .signup-form input[type="text"]:valid,
  .signup-form input[type="email"]:focus,
  .signup-form input[type="email"]:valid,
  .signup-form input[type="password"]:focus,
  .signup-form input[type="password"]:valid,
  .signup-form input[type="number"]:focus,
  .signup-form input[type="number"]:valid,
  .signup-form select:focus, .signup-form select:valid {
    color: white;
    outline: none;
  }
  
  /* Checkbox and its Label */
  .signup-form input[type="checkbox"] {
    margin-right: 10px; /* Gap between checkbox and its label */
  }
  
  /* Submit Button */
  .signup-form button {
    background-color: #703BF7;
    color: white;
    padding: 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
  }
  
  