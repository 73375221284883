.login-form {
    display: flex;
    flex-direction: column;
    gap: 20px; 
    max-width: 400px; 
    margin: 200px auto 0 auto;
    justify-content: center;
}

.login-form h2 {
    text-align: center;
    color: #FFF;
  }

  .forms-title {
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
    margin-top: 30px;
  }
  
  /* Labels */
  .login-form label {
    color: white;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px; /* Adjust the gap between label and input */
  }
  
  /* Inputs */
  .login-form input[type="text"],
  .login-form input[type="email"],
  .login-form input[type="password"],
  .login-form input[type="number"] {
    background-color: #1A1A1A;
    border-radius: 6px;
    border: 1px solid #262626;
    padding: 16px;
    font-size: 14px;
    color: #666666; /* Color when not filled */
  }
  
  /* Input: Focus and Filled */
  .login-form input[type="text"]:focus,
  .login-form input[type="text"]:valid,
  .login-form input[type="email"]:focus,
  .login-form input[type="email"]:valid,
  .login-form input[type="password"]:focus,
  .login-form input[type="password"]:valid,
  .login-form input[type="number"]:focus,
  .login-form input[type="number"]:valid {
    color: white; /* Color when filled */
  }
  
  /* Checkbox and its Label */
  .login-form input[type="checkbox"] {
    margin-right: 10px; /* Gap between checkbox and its label */
  }
  
  /* Submit Button */
  .login-form button {
    background-color: #703BF7; /* Example color, adjust as needed */
    color: white;
    padding: 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
  }
  

.button-upload-login {
    background-color: #1A1A1A;
    border-radius: 10px;
    border: 1px solid #262626;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    color: white;
    cursor: pointer;
}