.apartments-container {
    width: 100%;
    height: 100%;
    padding-top: 200px;
}

.apartments-first {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 160px;
    margin-bottom: 50px;
}

.apartments-title {
    color: white;
    font-size: 40px;
    font-weight: 600;
}

.apartments-text {
    color: #999999;
    font-size: 18px;
}

@media (max-width: 700px) { 
    .apartments-container {
        padding-top: 100px;
    }

    .apartments-title {
        font-size: 24px;
    }

    .apartments-first {
        padding: 0;
    }
}