.broker-details-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
}

@media (max-width: 700px) { 
    .broker-details-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }
}
