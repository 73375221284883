.users-container {
    color: white;
    width: 100%;
    height: 100%;
    padding: 200px 0;
}

.users-list {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.user-details-grid {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 20px;
}

.user-first {
    display: flex;
    gap: 20px;
    padding: 0 160px;
    margin-bottom: 30px;
    align-items: center;
}

.users-title {
    color: white;
    font-size: 24px;
    font-weight: 600;
}

.user-details-card-profile {
    border-radius: 12px;
    border: 1px solid #262626;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 0 160px;
}

.user-details-card-row-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.user-details-card-row-item-title {
    color: white;
    font-size: 14px;
    font-weight: 600;
}

.user-details-card-row-item-label {
    color: #999999;
    font-size: 14px;
}

.user-applied {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.user-applied-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

.user-applied-apartment {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}

@media (max-width: 700px) {
    .users-container {
        padding: 100px 0;
    }

    .user-first {
        display: flex;
        gap: 20px;
        padding: 0;
        margin-bottom: 30px;
        align-items: center;
    }

    .user-details-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }

    .user-details-card-profile {
        border-radius: 12px;
        border: 1px solid #262626;
        padding: 30px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin: 0;
    }

    .user-applied-row {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
    }
}