.policy-container {
    height: 100%;
    padding: 200px 160px 200px 160px;
    display: flex;
    flex-direction: column;
}

.policy-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
}

.policy-title {
    font-size: 18px;
    font-weight: 700;
    color: white;
}

.policy-text {
    font-size: 16px;
    color: rgb(220, 220, 220);
}

@media (max-width: 700px) {
    .policy-container {
        height: 100%;
        padding: 100px 0;
        display: flex;
        flex-direction: column;
    }
    
    .policy-column {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 30px;
    }
    
    .policy-title {
        font-size: 18px;
        font-weight: 700;
        color: white;
    }
    
    .policy-text {
        font-size: 16px;
        color: rgb(220, 220, 220);
    }
}