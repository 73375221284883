.filter-container {
    background-color: #1A1A1A;
    padding: 10px;
    border-radius: 12px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 50px;
    margin-left: 160px;
    margin-right: 160px;
  }
  
  .filter-dropdown {
    background-color: #141414;
    border: 1px solid #262626;
    color: #999999;
    padding: 20px;
    border-radius: 12px;
    outline: none; /* Remove default focus outline */
    -webkit-appearance: none; /* Remove default styling in WebKit browsers */
    -moz-appearance: none; /* Remove default styling in Firefox */
    appearance: none; /* General rule to remove default styling */
    flex-grow: 1;
    cursor: pointer;
  }
  
  .filter-dropdown:focus {
    outline: none; /* Optional: change border color on focus */
  }

  @media (max-width: 700px) { 
    .filter-container {
      background-color: #1A1A1A;
      padding: 10px;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      flex-wrap: wrap;
      margin-bottom: 50px;
      margin-left: 0;
      margin-right: 0;
    }
  }
  