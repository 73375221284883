.apartment-card {
    height: 600px;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    border: 1px solid #262626;
    padding: 30px;
    gap: 20px;
}

.apartment-media {
    max-height: 300px;
    height: 100%;
    width: 100%;
    /* object-fit: cover; */
    border-radius: 6px;
}

.apartment-info {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.apartment-info-top {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.apartment-title {
    font-size: 18px;
    font-weight: 500;
    color: white;
}

.apartment-description {
    font-size: 14px;
    font-weight: 500;
    color: #999999;
}

.card-bottom {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.price-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.apartment-price-tag {
    font-size: 14px;
    color: #999999;
}

.apartment-price {
    font-size: 18px;
    font-weight: 500;
    color: white;
}

.filter-tags {
    display: flex;
    gap: 10px;
  }
  
  .filter-tag {
    background-color: #1A1A1A;
    border: 1px solid #262626;
    border-radius: 28px;
    padding: 5px 15px;
    color: white;
    font-size: 14px;
  }

/* @media (max-width: 700px) {
    .apartment-card {
        height: 600px;
    }
} */
  