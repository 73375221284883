@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  font-family: 'Urbanist', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #141414;
  
}

/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 8px; /* Adjust width of the scrollbar */
  background-color: transparent; /* Background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #212121; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners of the scrollbar thumb */
  border: 2px solid #101010; /* Creates some space between the track and thumb */
}

/* Optional: Style for hover state of the scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
  background-color: #212121;
}

/* Optional: Style for the scrollbar corner (where horizontal and vertical scrollbars meet) */
::-webkit-scrollbar-corner {
  background-color: #212121;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 700px) {
  body {
    margin: 0 16px;
  }
  }


