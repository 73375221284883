.pricing-container {
    width: 100%;
    height: 100%;
}

.pricing-first {
    width: 100%;
    height: 100%;
    display: flex;
}

.price-left {
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-content: center;
    padding-top: 200px;
    padding-bottom: 100px;
    padding-left: 160px;
    gap: 20px;
}

.price-title {
    color: white;
    font-size: 48px;
    font-weight: 600;
}

.price-subtitle {
    color: #999999;
    font-size: 18px;
}

.popup-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: end;
    padding-top: 200px;
    padding-right: 160px;
}

.popup-pricing {
    background-image: url('../../assets/Popup.png');
    border-radius: 12px;
    border: 1px solid #C891FC;
    width: 300px;
    padding: 60px 40px;
    display: flex;
    flex-direction: column;
}

.popup-pricing-column {
    display: flex;
    flex-direction: column;
    gap: 60px;
    margin-bottom: 40px;
}

.popup-pricing-title {
    font-size: 20px;
    font-weight: 600;
    color: white;
}

.popup-pricing-adv {
    font-size: 16px;
    font-weight: 300;
    color: white;
}

.popup-pricing-price-column {
    display: flex;
    flex-direction: column;
}

.popup-pricing-price {
    font-size: 20px;
    font-weight: 600;
    color: white;
}

.popup-pricing-price-text {
    font-size: 16px;
    font-weight: 300;
    color: #999999;
}

.button-loading {
    animation: loading 1.5s infinite ease-in-out;
    border-radius: 10px;
    border: none;
    padding: 16px 24px;
    color: white;
    cursor: pointer;
}

.button-popup-pricing {
    background: rgb(200,145,252);
    background: linear-gradient(90deg, rgba(200,145,252,1) 0%, rgba(153,99,249,1) 35%, rgba(112,59,247,1) 100%);
    border-radius: 10px;
    border: none;
    padding: 16px 24px;
    color: white;
    cursor: pointer;
    text-decoration: none;
    justify-content: center;
    display: flex;
}

.pricing-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-top: 50px;
    padding: 0 160px;
}

.pricing-grid-card {
    background-color: #1A1A1A;
    border: 1px solid #262626;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.pricing-grid-title {
    font-size: 20px;
    font-weight: 700;
    color: #C891FC;
}

.pricing-grid-text {
    font-size: 16px;
    color: #999999;
}

.pricing-comparison {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 100px;
    padding: 0 160px;
}

.pricing-comparison-card {
    background-color: #1A1A1A;
    border: 1px solid #262626;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.pricing-comparison-card-title {
    font-size: 20px;
    font-weight: 700;
    color: white;
    margin-bottom: 16px;
}

.pricing-comparison-card-title-pro {
    font-size: 20px;
    font-weight: 700;
    color: #C891FC;
    margin-bottom: 16px;
}

.pricing-comparison-card-text {
    font-size: 16px;
    color: #999999;
}

.pricing-feedbacks-text-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px;
    margin-top: 100px;
    padding: 0 100px;
}

.pricing-feedbacks-title {
    color: white;
    font-size: 48px;
    font-weight: 600;
}

.pricing-feedbacks-text {
    font-size: 16px;
    color: #999999;
}

.pricing-feedbacks-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 50px;
    padding: 0 100px;
}

.pricing-feedbacks-card {
    border: 1px solid #262626;
    border-radius: 12px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    height: 250px;
}

.pricing-feedbacks-profile-row {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 20px;
}

.pricing-feedbacks-profile-media {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.pricing-feedbacks-profile-title {
    font-size: 20px;
    font-weight: 700;
    color: white;
}

.pricing-feedbacks-card-text {
    font-size: 16px;
    color: white;
}

.pricing-feedbacks-footer-row {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
}

.pricing-feedbacks-card-moving-text {
    font-size: 16px;
    color: #C891FC;
}

.pricing-feedbacks-card-area-text {
    font-size: 16px;
    color: #999999;
}

.pricing-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 70px 100px;
    background-image: url('../../assets/CTAMOBILE.png');
    background-size: cover;
    border-top: 1px solid #262626;
    margin-top: 100px;
    margin-bottom: 0;
}

.pricing-action-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 60%;
}

.pricing-action-title {
    color: white;
    font-size: 36px;
    font-weight: 600;
}

.pricing-action-text {
    font-size: 16px;
    color: #999999;
}


.home-container {
    width: 100%;
    height: 100%;
}

.hiro {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 200px;
    padding-bottom: 100px;
    text-align: center;
    gap: 20px;
}

.title {
    color: white;
    font-size: 60px;
    width: 70%;
    font-weight: 600;
}

.subtitle {
    color: #999999;
    font-size: 18px;
    width: 50%;
}

.buttons-row {
    display: flex;
    gap: 20px;
    margin-top: 30px;
}

.button-primary {
    background-color: #141414;
    border-radius: 10px;
    border: 1px solid #262626;
    padding: 16px 24px;
    color: white;
    cursor: pointer;
}

.button-active {
    background-color: #703BF7;
    border-radius: 10px;
    border: none;
    padding: 16px 24px;
    color: white;
    cursor: pointer;
}

.home-problem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 160px;
    padding-right: 160px;
    gap: 50px;
}

.problem-first {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    text-align: center;
}

.problem-title {
    color: white;
    font-size: 40px;
    font-weight: 600;
}

.problem-text {
    color: #999999;
    font-size: 18px;
}

.problem-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
}

.problem-card {
    border-radius: 10px;
    border: 1px solid #262626;
    box-shadow: rgba(247, 247, 247, 0.16) 0px 1px 4px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.problem-card-title {
    color: white;
    font-size: 24px;
    width: 100%;
    font-weight: 600;
}

.problem-card-text {
    color: #999999;
    font-size: 18px;
    width: 100%;
}

.problem-icon {
    width: 70px;
    height: 70px;
    object-fit: cover;
    margin-bottom: 30px;
}

.home-listings {
display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 0 160px;
  margin-bottom: 100px;
}




@keyframes loading {
    0% {
      background-color: #703BF7;
    }
    50% {
      background-color: #35197d;
    }
    100% {
      background-color: #703BF7;
    }
  }

  @media (max-width: 700px) {
    .pricing-first {
        width: 100%;
        height: 100%;
        gap: 30px;
        display: flex;
        flex-direction: column;
        justify-content: unset;
    }

    .pricing-feedbacks-grid {
        display: flex;
        flex-direction: row; /* Aligns items in a horizontal line */
        gap: 8px; /* Keeps the gap you had before */
        padding: 0;
        margin-top: 30px;
        overflow-x: auto; /* Enables horizontal scrolling */
        /* The following lines hide the scrollbar in different browsers */
        scrollbar-width: none; /* For Firefox */
        -ms-overflow-style: none;  /* For Internet Explorer and Edge */
    }

    .pricing-feedbacks-card {
        border: 1px solid #262626;
        border-radius: 12px;
        padding: 40px 20px;
        display: flex;
        flex-direction: column;
        height: 230px;
        min-width: 330px;
    }
    
    .pricing-feedbacks-grid::-webkit-scrollbar {
        display: none; /* For Chrome, Safari, and Opera */
    }

    .pricing-feedbacks-text-title {
        display: flex;
        flex-direction: column;
        align-items: unset;
        text-align: unset;
        gap: 10px;
        margin-top: 100px;
        padding: 0;
    }

    .pricing-feedbacks-title {
        color: white;
        font-size: 36px;
        font-weight: 600;
    }

    .pricing-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;
        padding: 0;
        margin-top: 30px;
    }

    .price-left {
        display: flex;
        flex-direction: column;
        width: 90%;
        padding-top: 140px;
        padding-bottom: 0;
        padding-left: 0;
        gap: 20px;
    }

    .price-title {
        color: white;
        font-size: 42px;
        font-weight: 600;
    }
    
    .price-subtitle {
        color: #999999;
        font-size: 18px;
    }

    .popup-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        width: unset;
    }

    .popup-pricing {
        background-image: url('../../assets/Popup.png');
        border-radius: 12px;
        border: 1px solid #C891FC;
        width: calc(100% - 80px);
        padding: 60px 40px;
        display: flex;
        flex-direction: column;
    }

    .pricing-comparison {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
        margin-top: 100px;
        padding: 0;
    }

    .pricing-action {
        display: flex;
        flex-direction: column;
        justify-content: unset;
        gap: 30px;
        align-items: unset;
        padding: 70px 0;
        background-image: url('../../assets/CTAMOBILE.png');
        background-size: cover;
        border-top: none;
        margin-top: 0px;
        margin-bottom: 60px;
    }

    .pricing-action-column {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
    }




    .title {
        color: white;
        font-size: 42px;
        width: 100%;
        font-weight: 600;
    }
    
    .subtitle {
        color: #999999;
        font-size: 18px;
        width: 100%;
    }

    .problem-cards {
        display: flex;
        flex-direction: column;
    }

    .problem-first {
        text-align: unset;
    }

    .home-problem {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 100px;
        padding-left: 0;
        padding-right: 0;
        gap: 50px;
    }

    .problem-card {
        border-radius: 10px;
        border: 1px solid #262626;
        box-shadow: rgba(247, 247, 247, 0.16) 0px 1px 4px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .hiro {
        padding-top: 160px;
    }

    .home-listings {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 0;
      margin-bottom: 100px;
      width: 100%;
    }
  }