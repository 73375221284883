.apartment-page {
    width: 100%;
    height: 100%;
    padding: 200px 0;
    color: white;
}

.apartment-page-title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 160px;
    margin-bottom: 50px;
}

.share-icon {
    width: 25px;
    height: 25px;
    object-fit: cover;
}

.apartment-page-title {
    color: white;
    font-size: 24px;
    font-weight: 600;
}

.apartment-details {
    display: flex;
    padding: 0 160px;
    width: calc(100%-320px); 
    gap: 100px;
}

.apartment-details-left {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.apartment-details-right {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.apartment-media-details {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 6px;
}

.thumbnail-images {
    display: flex;
    gap: 10px;
}

.apartment-thumbnail-media {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 6px;
    cursor: pointer;
}

.apartment-details-card {
    border-radius: 12px;
    border: 1px solid #262626;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.apartment-details-card-chat {
    border-radius: 12px;
    background-color: #1b1a1e;
    border: 1px solid #292730;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.apartment-details-card-chat-row-item-title {
    color: #786f8f;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
}

.button-active-chat {
    background-color: #423d4f;
    border-radius: 10px;
    border: none;
    padding: 16px 24px;
    color: white;
    cursor: pointer;
}

.apartment-details-card-price {
    border-radius: 12px;
    border: 1px solid #262626;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
    color: white;
    font-size: 24px;
    font-weight: 600;
    gap: 30px;
}

.apartment-details-card-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.apartment-details-card-row-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.apartment-details-card-row-item-title {
    color: white;
    font-size: 14px;
    font-weight: 600;
}

.apartment-details-card-row-item-label {
    color: #999999;
    font-size: 14px;
}

.apartment-details-card-nearby {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.apartment-details-card-nearby-item {
    background-color: #1f1f1f;
    border-left: 1px solid #703BF7;
    padding: 18px 24px;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    border-radius: 12px;
    background-image: url('../../assets/Popup2.png');
    border: 1px solid #292730;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 20px 30px 20px;
    opacity: 0;
    visibility: hidden;
    animation: popupAppear 0.2s ease-out forwards;
    z-index: 10000;
  }

  .popup-icon {
    width: 120px;
    object-fit: cover;
    margin: 30px 0;
  }

  .popup-title {
    margin-bottom: 20px;
    color: white;
    font-size: 16px;
  }

  .popup-button {
    background-color: #703BF7;
    border-radius: 10px;
    border: none;
    padding: 16px 60px;
    color: white;
    cursor: pointer;
  }

  .popup-x {
    position: fixed;
    top: 16px;
    right: 16px;
    font-size: 18px;
    color: white;
    cursor: pointer;
    font-weight: 700;
    border: none;
    background-color: transparent;
  }
  
  @keyframes popupAppear {
    0% {
      opacity: 0;
      visibility: visible;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }

  .apartment-details-created-date {
    color: #999999
  }


@media (max-width: 700px) {
    .apartment-page {
        width: 100%;
        height: 100%;
        padding: 100px 0;
        color: white;
    }

    .apartment-details {
        padding: 0;
        display: flex;
        flex-direction: column;
        width: 100%; 
        gap: 50px;
    }

    .apartment-details-left {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
        order: 2;
    }
    
    .apartment-details-right {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
        order: 1;
    }

    .apartment-page-title-row {
        display: flex;
        gap: 10px;
        flex-direction: column;
        justify-content: unset;
        align-items: unset;
        padding: 0;
        margin-bottom: 10px;
    }

    .apartment-details-card-nearby-mobile {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .apartment-details-created-date-mobile {
        color: #999999
    }

    .apartment-location {
        display: none;
    }

    .apartment-details-card-nearby {
        display: none;
    }

    .apartment-details-created-date {
        display: none;
    }

    .thumbnail-images {
        gap: 10px;
        display: flex;
        flex-direction: row; /* Aligns items in a horizontal line */
        overflow-x: auto; /* Enables horizontal scrolling */
        scrollbar-width: none; /* For Firefox */
        -ms-overflow-style: none;
    }

}

@media (min-width: 700px) {
    .apartment-details-card-nearby-mobile {
        display: none;
    }

    .apartment-details-created-date-mobile {
        display: none;
    }

    .apartment-location-mobile {
        display: none;
    }
}
  
