.broker-signup-container {
    width: 100%;
    height: 100%;
    display: flex;
}

.popup-broker-signup {
    background-image: url('../../assets/Popup.png');
    border-radius: 12px;
    border: 1px solid #303030;
    padding: 60px 40px;
    display: flex;
    flex-direction: column;
}

@media (max-width: 700px) {
    .broker-signup-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    .popup-broker-signup {
        margin-bottom: 100px;
    }
}